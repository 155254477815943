<template>
    <b-modal title="Uporabnik" v-model="modalActive" size="lg" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group label="Ime">
                    <validation-provider #default="{ errors }" name="Ime" rules="required">
                        <b-form-input v-model="object.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email">
                        <!-- Tukaj je v input nastavljen atribut autocomplete="new-password" ker je to edini način kako disable autofill v chromu. Kajkoli drugega vneseš ne dela -->
                        <b-form-input autocomplete="new-password" :disabled="disabledEmail"  v-model="object.email"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group v-if="editing" label="Geslo">
                    <validation-provider #default="{ errors }" name="Geslo">
                        <b-form-input autocomplete="new-password" v-model="object.password" placeholder="Prazno polje pomeni nespremenjeno geslo" type="password"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group v-else label="Geslo">
                    <validation-provider #default="{ errors }" name="Geslo" rules="required">
                        <!-- Tukaj je v input nastavljen atribut autocomplete="new-password" ker je to edini način kako disable autofill v chromu. Kajkoli drugega vneseš ne dela -->
                        <b-form-input autocomplete="new-password" v-model="object.password" type="password"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Administrator">
                    <v-select v-model="object.user_type" :options="UserTypes" label="name" :reduce="(item) => item.value" :clearable="false" appendToBody />
                </b-form-group>
            </validation-observer>
            <div v-if="editing">
                <div>
                    Uporabnik dodan na strankah
                </div>

                <basic-table :columns="columns" :pageLength="8" :lengths="['8']" :data="client_ids" v-slot="props" >
                    <span v-if="props.column.field === 'id'">
                        {{getClientName(props.row.id) }}
                    </span>
                    <span class="d-flex justify-content-center" v-else-if="props.column.field === 'actions'">
                        <b-button variant="danger" class="" @click="remove(props.row.id)"><feather-icon icon="Trash2Icon"/></b-button>
                    </span>
                </basic-table>


            </div>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Uredi</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">Dodaj</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'
    import BasicTable from '@/views/components/BasicTable.vue'
    import {UserTypes} from '@/libs/enums/UserTypes'
    import vSelect from 'vue-select'
    import '@core/scss/vue/libs/vue-select.scss'

    export default {
        props: {
            clients: {
                type: Array
            }
        },
        components: {
            BasicTable,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        computed: {
            UserTypes() {
                return [
                    {
                        name: 'Navadni uporabnik',
                        value: UserTypes.User
                    },
                    {
                        name: 'Admin',
                        value: UserTypes.Admin
                    },
                    {
                        name: 'Super admin',
                        value: UserTypes.SuperAdmin
                    }
                ]
            },

            client_ids() {
                const array = []
                if (this.object && this.object.client_ids && this.object.client_ids.length > 0) {
                    for (const item of this.object.client_ids) {
                        array.push({
                            id: item
                        })
                    }
                }

                return array
            }

        },
        data() {
            return {
                modalActive: false,
                object: {
                    name: '',
                    email: '',
                    password: '',
                    client_ids: [],
                    user_type: 0
                },
                editing: false,
                disabledEmail: true,
                required,
                email,
                columns: [
                    {
                        label: 'Naziv',
                        field: 'id'
                    },
                    {
                        label: 'Možnosti',
                        field: 'actions'
                    }
                ]

            }
        },
        methods: {
            async remove(clientId) {
                try {
                    await this.$http.delete(`/api/admin/v1/client/${clientId}/${ this.object.id}`)
                    this.$printSuccess('Uporabnik uspešno izbrisan')

                    const res = await this.$http.get(`/api/admin/v1/users/${ this.object.id}`)
                    this.object = res.data
                } catch (err) {
                    this.$printError(err)
                }
            },
            getClientName(client_id) {
                const item = this.clients.find(ele => ele.id === client_id)

                if (item) {
                    return item.name
                }

                return ''
            },
            open(data = null) {
                if (data !== null) {
                    this.object = JSON.parse(JSON.stringify(data))
                    this.editing = true
                    this.disabledEmail = true
                } else {
                    this.disabledEmail = false
                    this.object = {
                        name: '',
                        email: '',
                        password: '',
                        client_ids: [],
                        user_type: 0
                    }
                    this.editing = false
                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) => {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {

                try {
                    const response = await this.$http.post('/api/admin/v1/users', this.object)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess('Dodajanje je bilo uspešno!')
                    this.object = {
                        name: '',
                        email: '',
                        password: '',
                        user_type: 0
                    }
                } catch (error) {
                    if (error && error.response && error.response.data && (error.response.data.error === 'user with this email exists')) {
                        this.$printError('Uporabnik s tem emailom že obstaja')
                    } else {
                        this.$printError('Prišlo je do napake')
                    }
                }
            },
            async edit() {
                try {
                    await this.$http.put(`/api/admin/v1/users/${this.object.id}`, this.object)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess('Urejanje je bilo uspešno!')
                    this.object = {
                        name: '',
                        email: '',
                        password: '',
                        user_type: 0
                    }
                } catch (error) {
                    if (error && error.response && error.response.data && (error.response.data.error === 'user with this email exists')) {
                        this.$printError('Uporabnik s tem emailom že obstaja')
                    } else {
                        this.$printError('Prišlo je do napake')
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
